* {
    box-sizing: border-box;
}

body {
    margin: 0 auto;
    max-width: 1366px;

    @media (min-width: 824px) {
        background-color: whitesmoke;
    }
}
